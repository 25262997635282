import '@hotwired/turbo-rails';
import './channels';
import './controllers';
import * as Routes from './routes';

window.Routes = Routes;

export default {
  init: () => {
  }
};
