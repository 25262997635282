import { Controller } from '@hotwired/stimulus'
import Validator from '../../shared/validator';
import CountryStateSelect from '../../shared/country_state_select';

export default class extends Controller {
  static values = {
    event: String,
    product: String,
    account: String,
    user: Number
  }

  static targets = [
    'editMode',
    'formToggle',
    'field'
  ];

  editMode() {
    this.editModeTargets.forEach(target => {
      target.classList.toggle('hidden');
    });

    this.storedValues = {};

    this.fieldTargets.forEach(field => {
      field.toggleAttribute('disabled');
      this.storedValues[field.id] = field.value;
    });
  }

  cancelEdit () {
    for (let key in this.storedValues) {
      document.getElementById(key).value = this.storedValues[key];
    }

    window.countryState.selectState();
    this.editMode();
    this.hideError();
  }

  selectState () {
    window.countryState.selectState();
  }

  initializeProfile () {
    document.getElementById('country').value = document.getElementById('country').dataset.selected;
    window.countryState.selectState();
    document.getElementById('editProfile')?.classList.remove('hidden');
  }

  validate (event) {
    if (!this.validator.validate()) {
      event.preventDefault();
    } else {
      // when there are no errors push event to Google Tag Manager
      window.dataLayer.push({
        event: this.eventValue,
        product: this.productValue,
        account_id: this.accountValue,
        user_id: this.userValue
      });
    }

    this.hideError();
  }

  hideError() {
    const errorNotification = document.getElementById('company-profile-notification');

    if (errorNotification) {
      errorNotification.hidden = true;
    }
  }

  connect() {
    let validate = {
      'linked_in_url': ['validURLOrEmpty', 'Invalid LinkedIn URL.'],
      'twitter': ['validTwitterHandleOrEmpty', 'Invalid Twitter URL or Twitter handle'],
      'website_url': ['validURL', 'Invalid website URL.'],
      'vendor_number_of_employees': [['notEmpty', 'Employee count cannot be empty.'], ['greaterThan', 'Employee count must be greater than 0.', 0]],
      'phone_number': ['notEmpty', 'Phone cannot be blank.'],
      'street': ['notEmpty', 'Address cannot be blank.']
    };

    this.validator = new Validator(validate);
    window.countryState = new CountryStateSelect();

    this.initializeProfile();

    this.element.addEventListener('turbo:frame-render', (event) => {
      window.countryState.selectState();
      this.editMode();
    });

    var observeDOM = (function() {
      var MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

      return function( obj, callback ){
        if (!obj || obj.nodeType !== 1) return;
        if (MutationObserver) {
          // define a new observer
          var mutationObserver = new MutationObserver(callback);
          // have the observer observe foo for changes in children
          mutationObserver.observe(obj, { childList:true });
          return mutationObserver
        } else if (window.addEventListener) { // browser support fallback
          obj.addEventListener('DOMNodeInserted', callback, false)
          obj.addEventListener('DOMNodeRemoved', callback, false)
        }
      }
    })();

    this.vendor_id = document.getElementById('vendor_id').value;

    observeDOM(document.getElementById('vendor_' + this.vendor_id), function (events) {
      window.countryState.selectState();
    });
  }
}
