import { Controller } from '@hotwired/stimulus';
import * as Turbo from "@hotwired/turbo"
import { post } from '@rails/request.js';
import * as Routes from '../routes';

export default class extends Controller {
  static values = {
    id: Number,
    publicationId: Number,
    userId: Number,
    userRequestType: String,
    guestAllowed: { default: false, type: Boolean }
  }

  download() {
    let request;

    switch(this.userRequestTypeValue) {
      case 'download_blueprint': request = this.#userRequestAndDownload();
      break;
      default:
        this.#userRequest();
        request = this.#downloadAttachment();
    }
  }

  #downloadAttachment() {
    return post(Routes.download_attachment_path({ id: this.idValue },
                                                { guest_allowed: this.guestAllowedValue, format: 'turbo_stream' }), {});
  }

  #userRequestAndDownload() {
    return post(Routes.user_requests_path('turbo_stream',
                                          { user_request: { publication_id: this.idValue, user_id: this.userIdValue, request_type: this.userRequestTypeValue }}), {});
  }

  #userRequest() {
    return post(Routes.user_requests_path('turbo_stream',
                                          { only_create_user_request: true, user_request: { publication_id: this.publicationIdValue, user_id: this.userIdValue, request_type: this.userRequestTypeValue }}), {});
  }
}
