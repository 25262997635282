import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { siteKey: String };

  initialize() {
    if (typeof grecaptcha === 'undefined') return;

    document.getElementById('recaptchaV2').innerHTML = '';

    grecaptcha.render("recaptchaV2", { sitekey: this.siteKeyValue, theme: 'dark' } );
  }
}
