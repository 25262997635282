import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  initialize() {
    this.setDataTurbo();
  }

  setDataTurbo() {
    for(let element of this.element.getElementsByTagName('a')) {
      if (element.href.includes('#')) {
        element.setAttribute('data-turbo', 'false');
      }
    }
  }
}
