import Sortable from 'stimulus-sortable';

import { Controller } from '@hotwired/stimulus';
import { FetchRequest } from '@rails/request.js';
import { post, put } from '@rails/request.js';
import * as Routes from '../routes';

import {
  setGlobalPendingChanges
} from '../helpers';

const TYPE_PROFILE_VIDEOS = 'profile_videos';

export default class extends Sortable {
  static targets = ['asset'];

  static values = {
    totalCount: { type: Number, default: 0 },
    deletedCount: { type: Number, default: 0 },
    attachmentType: String,
    offeringId: Number,
    vendorId: Number
  }

  get defaultOptions() {
    return {
      draggable: '.js-sortable',
      ghostClass: 'opacity-50',
      onEnd: this.end.bind(this)
    }
  }

  connect() {
    super.connect();
    this.sortable;
    this.defaultOptions;
    this.totalCountValue = this.assetTargets.length;
    this.updateAll;
  };

  end({ newIndex, item: { dataset: { id } } }) {
    let item = this.assetTargets.find(target => target.dataset.id == id);
    let itemSortable = this.application.getControllerForElementAndIdentifier(
      item,
      'asset-sortable'
    );

    let oldIndex = parseInt(itemSortable.orderTarget.value);
    let index = parseInt(itemSortable.indexTarget.value);

    this.updateAll();
    this.#sortableMoved(oldIndex, newIndex);
  };

  remove({ params: { id } }) {
    let type = this.data.get('type');
    let assets = this.assetTargets;
    let asset = this.assetTargets.find(target => target.dataset.id == id);
    let assetSortable = this.application.getControllerForElementAndIdentifier(
      asset,
      'asset-sortable'
    );
    let index = assetSortable.indexTarget.value;
    let oldPosition = assetSortable.orderTarget.value;
    let _destroy = assetSortable.destroyTarget.value;

    assetSortable.destroyTarget.value = 'true';
    asset.classList.add('hidden');
    this.deletedCountValue++;
    asset.classList.remove(this.data.get('type'));

    this.#updateDraftDeleteController(assetSortable.attachmentIdValue);
    this.updateAll();

    this.#setPendingChanges();
  }

  updateAll() {
    const positions = [];
    for (let i = 0, j = 1; i < this.assetTargets.length; i++) {
      let item = this.application.getControllerForElementAndIdentifier(
        this.assetTargets[i],
        'asset-sortable'
      );
      if (item.destroyTarget.value === 'false') {
        this.#updateItemPosition(item, j);
        positions.push([item.attachmentIdValue, j]);
        j++;
      }
    }
    this.#updateDraftPositionsController(JSON.stringify(positions));
  }

  #updateItemPosition(item, position) {
    item.orderTarget.value = position;
    item.showOrderTarget.textContent = position;
  }

  #sortableMoved(oldIndex, newIndex) {
    // In the new UI, we have different arrangements for the cards in the new videos section
    // and the screenshots and downloads. That causes the newIndex to be off by 1 in relation
    // with profile_videos and screenshots or downloads
    let compareValue = (this.attachmentTypeValue === TYPE_PROFILE_VIDEOS) ? newIndex + 1 : newIndex
    if (oldIndex !== compareValue) {
      this.#setPendingChanges();
    }
  }

  #updateDraftPositionsController(positions) {
    const formData = new FormData();
    formData.append('attachment_type', this.attachmentTypeValue);
    formData.append('positions', positions);

    put(Routes.update_draft_positions_vendor_offering_attachments_path(this.vendorIdValue, this.offeringIdValue), {
      body: formData,
      responseKind: 'json'
    });
  }

  #updateDraftDeleteController(attachmentId) {
    const formData = new FormData();
    formData.append('attachment_type', this.attachmentTypeValue);

    put(Routes.update_draft_delete_vendor_offering_attachment_path(this.vendorIdValue, this.offeringIdValue, attachmentId), {
      body: formData,
      responseKind: 'json'
    });
  }

  #setPendingChanges() {
    setGlobalPendingChanges();
    this.dispatch('setPendingChanges');
  }
}
