import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { type: String }

  static targets = [
    'form',
    'email'
  ];

  addTextbox() {
    let select_id = `${this.typeValue}_select`;
    let textbox_id = `${this.typeValue}_textbox`;
    let select = document.getElementById(select_id);
    if (select.value === `other_${this.typeValue}`) {
      document.getElementById(textbox_id).classList.remove('hidden');
      document.getElementById(textbox_id).parentElement.classList.remove('hidden');
    }
    else {
      document.getElementById(textbox_id).classList.add('hidden');
      document.getElementById(textbox_id).parentElement.classList.add('hidden');
    }
  }

  validateEmail(event) {
    let isValid = this.#emailIsValid(this.emailTarget.value);

    if (!isValid) {
      event.preventDefault();
      this.emailTarget.focus();
      this.emailTarget.setCustomValidity('Please enter a valid email address.');
      this.emailTarget.reportValidity();
    } else {
      this.emailTarget.setCustomValidity('');
    }

    return isValid;
  }

  resetValidity() {
    this.emailTarget.setCustomValidity('');
  }

  #emailIsValid(email) {
    if (email.length > 253) { return false; };
    return /^(?![^@]{65})[\+\w]+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }
}
