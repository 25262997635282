import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'table'
  ];

  initialize() {
    this.#addTableTargets();
    this.#wrapTables();
  }

  #addTableTargets() {
    document.querySelectorAll('table:not(table table)').forEach((table) => {
      table.setAttribute('data-tables-responsive-target', 'table');
    });
  }

  #wrapTables() {
    this.tableTargets.forEach((table) => {
      const container = document.createElement('div');
      const indicator = document.createElement('div');
      const wrapper = document.createElement('div');

      table.parentNode.insertBefore(wrapper, table);

      container.classList.add('table-responsive__container');
      indicator.classList.add('table-responsive__indicator');
      wrapper.classList.add('table-responsive');

      container.appendChild(table);
      wrapper.appendChild(container);
      wrapper.appendChild(indicator);
    });
  }
}
