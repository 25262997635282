import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'containerNotification'
  ];

  initialize() {
    this.headerHeight = 0;
    this.notificationContainer = document.getElementById('notification-container');

    this.#layout();
    this.#moveNotifications();

    window.addEventListener('resize', this.#layout.bind(this));
  }

  close() {
    event.currentTarget.parentElement.remove();
  }

  #layout() {
    this.headerHeight = document.getElementById('header')?.offsetHeight || 0;
    this.notificationContainer.parentElement.style.top = `${this.headerHeight}px`;
  }

  #moveNotifications() {
    this.containerNotificationTargets.forEach(notification => {
      this.notificationContainer.append(notification);
    });
  }
}
