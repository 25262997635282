import { Controller } from '@hotwired/stimulus';
import Cookies from 'js-cookie';

export default class extends Controller {
  static values = {
    cookie: Object
  }

  checkCookie() {
    const name = this.cookieValue.name;

    if (Cookies.get(name)) {
      this.dispatch('found');
    } else {
      this.dispatch('notFound');
    }

    return Cookies.get(name);
  }

  removeCookie(name) {
    Cookies.remove(name, { path: '' });
  }

  setCookie() {
    const { name, value, expires } = this.cookieValue;
    let cookieAtts = { sameSite: 'strict' };

    if (expires) {
      cookieAtts.expires = Number(expires);
    }

    Cookies.set(name, value, cookieAtts);
    this.dispatch('set');
  }

  updateCookie() {
    this.removeCookie(this.cookieValue.name);
    this.setCookie();
    this.dispatch('update');
  }
}
