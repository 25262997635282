import consumer from './consumer';

consumer.subscriptions.create('PushAlertChannel', {
  received(data_request_url) {
    if (data_request_url) {
      fetch(data_request_url)
        .then(response => {
          if (response.status !== 204) {
            return response.json();
          }
        })
        .then(data => {
          if (data) {
            Turbo.renderStreamMessage(data.html_message);  
          }
        })
        .catch(error => console.error('Error: ', error));
    }
  }
});
