import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'clear',
    'clearFilter',
    'form',
    'input',
    'submit'
  ];

  static values = {
    interval: { default: 500, type: Number }
  }

  // Check if "clear" button should show
  clearAbility() {
    const empty = this.inputTarget.value === '';

    if (empty) {
      this.clearTarget.classList.add('hidden');
    } else {
      this.clearTarget.classList.remove('hidden');
    }

    return empty;
  }

  // Clear search input
  clearInput() {
    this.inputTarget.value = '';
    this.dispatch('filterAbility', { detail: { active: this.clearAbility() } });
    this.submitForm();
  }

  inputTargetConnected() {
    this.dispatch('filterAbility', { detail: { active: this.clearAbility() } });
  }

  searchAbility({ detail: { filters } }) {
    if (filters.length) {
      this.#searchDisable();
    } else {
      this.#searchEnable();
    }
  }

  #searchDisable() {
    this.clearTarget.setAttribute('disabled', 'disabled');
    this.inputTarget.setAttribute('disabled', 'disabled');
    this.submitTarget.setAttribute('disabled', 'disabled');
  }

  #searchEnable() {
    this.clearTarget.removeAttribute('disabled');
    this.inputTarget.removeAttribute('disabled');
    this.submitTarget.removeAttribute('disabled');
  }

  submit() {
    this.element.requestSubmit();
  }

  submitForm() {
    this.formTarget.requestSubmit();
  }

  unhideClearFilter() {
    this.clearFilterTarget.classList.remove('hidden');
  }
}
