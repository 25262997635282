import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    cutoffPoint: Number
  }

  initialize() {
    this.locked = false;
  }

  hidePaywallText() {
    let srPaywall = document.getElementById('sr-publication-paywall');
    if (srPaywall) { srPaywall.classList.add('hidden'); }
  }

  hideContent() {
    if (!this.locked) {
      let sections = document.querySelectorAll("#long-form > section");

      for (let i = this.cutoffPointValue; i < sections.length; i++) {
        sections[i].remove();
      }

      this.addPaywall();
      this.hideDownloadLinks();
      this.locked = true;
    }
  }

  hideDownloadLinks() {
    for(let element of this.element.getElementsByTagName('a')) {
      if (element.href.includes('users/sign_in')) {
        element.setAttribute('hidden', 'true');
      }
    }
  }

  hidePublicationContent() {
    let publicationBody = document.querySelectorAll("#pub-body > *");

    if (!this.locked && publicationBody?.length > 0) {
      let foundPaywall = Array.from(publicationBody).findIndex(el => el.querySelector('#sr-publication-paywall') !== null);
      let cutoff = Math.ceil(publicationBody.length / 4);
      if (foundPaywall !== -1) { cutoff = foundPaywall };

      for (let i = cutoff; i < publicationBody.length; i++) {
        publicationBody[i]?.remove();
      }

      if (cutoff > 0) {
        this.addPaywall();
        this.locked = true;
      }
    }
  }

  addPaywall() {
    document.getElementById('unlock-content').classList.remove('hidden');
  }
}
