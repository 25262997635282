import NestedForm from "stimulus-rails-nested-form"

import { Controller } from "@hotwired/stimulus"

import {
  setGlobalPendingChanges
} from '../helpers';

const MAX_FILES = 12

export default class extends NestedForm {
  static targets = [
    'template',
    'order',
    'index',
    'destroy'
  ];

  add(event) {
    event.preventDefault();
    let currentValidItems = this.currentValidItems();

    if (currentValidItems < MAX_FILES) {
      let index = currentValidItems + 1;
      let content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, index);
      this.targetTarget.insertAdjacentHTML('beforebegin', content);
      let reference = `offering_social_videos_attributes_${index}_`;
      let sortOrderField = document.getElementById(`${reference}sort_order`);

      sortOrderField.value = index;
      setGlobalPendingChanges();
    }
  }

  remove(event) {
    super.remove(event);
    let socialVideos = event.target.closest('[class^="nested-form-wrapper social_videos"],[class*="form_"]');
    let target = event.target.closest("[class^='js-sortable cursor-grab']");
    let assetSortable = this.application.getControllerForElementAndIdentifier(
      target,
      'asset-sortable'
    );

    let removedSortOrder = assetSortable.orderTarget.value;
    this.updateSortOrder(removedSortOrder, socialVideos);
    setGlobalPendingChanges();
  }

  currentValidItems() {
    let forms = document.querySelectorAll('[class^="nested-form-wrapper social_videos"],[class*="form_"]');
    let currentValidItems = 0;
    for (let i = 0; i < forms.length; i += 1) {
      let destroyField = forms[i].querySelector("[id$='_destroy']");
      if (destroyField.value == 'false') currentValidItems += 1;
    }
    return currentValidItems;
  }

  updateSortOrder(removedSortOrder, socialVideos) {
    for (let i = 0; i < socialVideos.length; i += 1) {
      let item = this.application.getControllerForElementAndIdentifier(
        socialVideos[i],
        'asset-sortable'
      );
      let currentSortOrder = item.orderTarget.value;
      // When we iterate over an already removed element, it would exist as a form
      // but will no longer have content inside
      if (currentSortOrder != null && currentSortOrder > removedSortOrder) item.orderTarget.value -= 1;
    }
  }
}
