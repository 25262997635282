import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'tab',
    'tablist',
    'tabpanel',
  ];

  initialize() {
    this.keys = {
      end: 35,
      home: 36,
      left: 37,
      up: 38,
      right: 39,
      down: 40,
      enter: 13,
      space: 32
    }

    // Open first panel but don't focus it
    this.activate(0, false);
  }

  activate(index, setFocus) {
    let tab = this.tabTargets[index] || event.currentTarget;
    let controls = tab.getAttribute('aria-controls');
    setFocus === undefined ? true : setFocus;

    this.deactivates();

    tab.removeAttribute('tabindex');
    tab.setAttribute('aria-selected', 'true');
    document.getElementById(controls).removeAttribute('hidden');

    if (setFocus) {
      tab.focus();
    }

    // Prevent submitting forms when containing tabs
    if (typeof event !== 'undefined') {
      event.preventDefault();
    }
  }

  deactivates() {
    this.tabTargets.forEach(tab => {
      tab.setAttribute('tabindex', '-1');
      tab.setAttribute('aria-selected', 'false');
    });

    this.tabpanelTargets.forEach(tabpanel => {
      tabpanel.setAttribute('hidden', 'hidden');
    });
  }

  determineOrientation(event) {
    // When a tablist's aria-orientation is set to vertical, only up and down arrow should function. In all other cases only left and right arrow function.
    const key = event.keyCode;
    const vertical = this.tablistTargets[0].getAttribute('aria-orientation') === 'vertical';
    let proceed = false;

    if (vertical) {
      if (key === this.keys.up || key === this.keys.down) {
        event.preventDefault();
        proceed = true;
      }
    } else {
      proceed = key === this.keys.left || key === this.keys.right;
    }

    if (proceed) {
      this.switchTabOnArrowPress(event);
    }
  }

  focusFirstTab() {
    this.tabTargets[0].focus();
  }

  focusLastTab() {
    this.tabTargets[this.tabTargets.length - 1].focus();
  }

  keydownEvent(event) {
    const key = event.keyCode;

    switch (key) {
      case this.keys.end:
        event.preventDefault();
        this.focusLastTab();
        break;
      case this.keys.home:
        event.preventDefault();
        this.focusFirstTab();
        break;
      // Up and down are in keydown because we need to prevent page scroll
      case this.keys.u:
      case this.keys.down:
        this.determineOrientation(event);
        break;
    }
  }

  keyupEvent(event) {
    const key = event.keyCode;

    switch (key) {
      case this.keys.left:
      case this.keys.right:
        this.determineOrientation(event);
        break;
      case this.keys.enter:
      case this.keys.space:
        this.activate(event.target);
        break;
    }
  }

  switchTabOnArrowPress(event) {
    const pressed = event.keyCode;
    const direction = {
      37: -1,
      38: -1,
      39: 1,
      40: 1
    }

    if (direction[pressed]) {
      const target = event.currentTarget;
      const index = [...target.parentElement.children].indexOf(target)
      const tabs = this.tabTargets;

      if (index !== undefined) {
        if (tabs[index + direction[pressed]]) {
          tabs[index + direction[pressed]].focus();
        } else if (pressed === this.keys.left || pressed === this.keys.up) {
          this.focusLastTab();
        } else if (pressed === this.keys.right || pressed == this.keys.down) {
          this.focusFirstTab();
        }
      }
    }
  }
}
