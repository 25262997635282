import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    userId: Number,
    firstName: String,
    lastName: String,
    initialAccess: Array
  }

  static targets = [
    'firstNameInput',
    'lastNameInput',
    'accessError',
    'accessLevel',
    'saveAccessLevel'
  ]

  accessValue(event) {
    let newAccessValues = [];
    this.accessLevelTargets.forEach((element) => {
      if (element.checked) { newAccessValues.push(element.value); }
    });

    if (JSON.stringify(newAccessValues) === JSON.stringify(this.initialAccessValue)) {
      this.accessErrorTargets.forEach((element) => {
        element.classList.remove('hidden');
      });
      this.saveAccessLevelTargets.forEach((element) => {
        element.disabled = true;
        element.classList.add('opacity-50');
      });
    } else {
      this.accessErrorTargets.forEach((element) => {
        element.classList.add('hidden');
      });
      this.saveAccessLevelTargets.forEach((element) => {
        element.disabled = false;
        element.classList.remove('opacity-50');
      });
    }
  }

  nameValue() {
    if (
      this.firstNameValue === this.firstNameInputTarget.value &&
      this.lastNameValue === this.lastNameInputTarget.value ||
      this.firstNameInputTarget.value === '' ||
      this.lastNameInputTarget.value === ''
    ) {
      document.getElementById(`save_contact_${this.userIdValue}`).disabled = true;
      document.getElementById(`save_contact_${this.userIdValue}`).classList.add('opacity-50');
    } else {
      document.getElementById(`save_contact_${this.userIdValue}`).disabled = false;
      document.getElementById(`save_contact_${this.userIdValue}`).classList.remove('opacity-50');
    }
  }

  removeTargetAnimation() {
    // Stop newly added rows from re-animating when team members table is toggled
    document.querySelectorAll('#team-members .table-row.animate-target-fade').forEach(row => {
      row.classList.remove('animate-target-fade');
    });
  }
}
