import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    account: Number,
    category: String,
    user: Number,
    contentType: String,
    event: String,
    fileType: String,
    fileTitle: String,
    formType: String,
    newObjectAdded: Boolean,
    product: String
  };

  connect() {
    if (this.newObjectAddedValue) {
      this.dataLayerPush();
    }
  }

  dataLayerPush() {
    window.dataLayer.push({
      account_id: this.accountValue,
      category: this.categoryValue,
      user_id: this.userValue,
      content_type: this.contentTypeValue,
      event: this.eventValue,
      file_type: this.fileTypeValue,
      file_title: this.fileTitleValue,
      form_type: this.formTypeValue,
      product: this.productValue
    });
  }
}
