import Dropzone from 'dropzone';
import { Controller } from '@hotwired/stimulus';
import { FetchRequest } from '@rails/request.js';

import {
  getMetaValue,
  findElement,
  requestUpdateDraggable,
  setGlobalPendingChanges
} from '../helpers';
import { divideColor } from 'tailwindcss/defaultTheme';

const TIMEOUT = 2000;

export default class extends Controller {
  static targets = ['input'];

  static outlets = ['sortable', 'asset-sortable', 'social-video-uploader'];

  static values = {
    invalidFileTypeMsg: String,
    maxFilesExceededMsg: String
  }

  connect() {
    this.dropZone = this.createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
    if (this.element.classList.contains('disable')) this.dropZone.disable();
    if (this.hasInvalidFileTypeMsgValue) this.dropZone.options.dictInvalidFileType = this.invalidFileTypeMsgValue;
    if (this.hasMaxFilesExceededMsgValue) this.dropZone.options.dictMaxFilesExceeded = this.maxFilesExceededMsgValue;
  }

  assetSortableOutletConnected() {
    this.assetSortableOutlet.attachmentTypeValue = this.uploadType;
  }

  addFileIcon(file) {
    let ext = file.name.split('.').pop();
    let colorClass;
    let iconClass;
    let fileIconNode = file.previewElement.querySelector('.file-icon');

    if (ext == 'pdf') {
      iconClass = 'fa-file-pdf-o'
      colorClass = 'text-pdf-primary'
    } else if (ext.indexOf('doc') !== -1) {
      iconClass = 'fa-file-word-o'
      colorClass = 'text-word-primary'
    } else if (ext.indexOf('xls') !== -1) {
      iconClass = 'fa-file-excel-o'
      colorClass = 'text-excel-primary'
    } else if (ext.indexOf('ppt') !== -1) {
      iconClass = 'fa-file-powerpoint-o'
      colorClass = 'text-powerpoint-primary'
    } else if (ext.indexOf('ogv') !== -1 || ext.indexOf('ogg') !== -1 || ext.indexOf('mp4') !== -1 || ext.indexOf('mpeg') !== -1 || ext.indexOf('webm') !== -1) {
      iconClass = 'fa-file-video-o'
      colorClass = 'text-secondary'
    }

    if (fileIconNode !== undefined || iconClass !== null) {
      fileIconNode.classList.add('fa', iconClass, colorClass)
    }
  }

  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = 'none';
  }

  reduceMaxFiles() {
    this.dropZone.options.maxFiles = this.dropZone.options.maxFiles - 1;
  }

  bindEvents() {
    this.dropZone.on('addedfile', file => {
      if (this.isVideoUpload()) this.showVideoDisclaimer();
      this.addFileIcon(file);
      setTimeout(async () => {
        file.accepted;
      }, 500);
    });

    this.dropZone.on('error', (file, message) => {
      if (this.isVideoUpload()) this.hideVideoDisclaimer();
      for (let node of file.previewElement.querySelectorAll(
        '[data-dz-errormessage]'
      )) {
        node.classList.remove('hidden')
      }
    });

    this.dropZone.on('success', (file, response) => {
      if (response.status === 'ok') {
        this.sortableOutlet.totalCountValue++;
        if (this.hasSocialVideoUploaderOutlet) this.socialVideoUploaderOutlet.reduceMaxVideos();
        this.reduceMaxFiles();
        if (file.previewElement) {
          file.previewElement.classList.add('dz-success');
          setTimeout(async () => { this.dropZone.removeFile(file) }, TIMEOUT);
        }

        requestUpdateDraggable(response.id, this.uploadType, this.sortableOutlet.totalCountValue, this.sortableOutlet.deletedCountValue, this.vendor, this.offering);
        this.#setPendingChanges();
      } else {
        let message = '';
        if (file.previewElement) {
          file.previewElement.classList.add('dz-error');

          if (typeof response !== 'string' && response.message) {
            message = response.message;
          } else if (response.status) {
            switch(response.status) {
              case 460,'460':
                this.dropZone.emit('maxfilesexceeded', file);
                message = this.dropZone.options.dictMaxFilesExceeded;
                break;
              default:
                message = 'An error has occurred. Please try again.';
                break;
              }
          } else {
            message = response;
          }

          file.status = Dropzone.ERROR;

          for (let node of file.previewElement.querySelectorAll(
            '[data-dz-errormessage]'
          )) {
            node.classList.remove('hidden');
            node.textContent = message;
          }
        }
      }

      if (this.isVideoUpload()) this.hideVideoDisclaimer();
    });

    this.dropZone.on('sending', (file, xhr, formData) => {
      formData.append('vendor_id', this.vendor);
      formData.append('offering_id', this.offering);
      formData.append('object_type', this.uploadType);
      formData.append('filename', file.name);
      formData.append('filetype', file.type);
    });

    this.dropZone.on('uploadprogress', (file, progress, bytesSent) => {
      findElement(
        file.previewTemplate,
        '.progress-bar'
      ).style.width = `${Math.round(progress)}%`;

      if (progress === 100) {
        findElement(
          file.previewTemplate,
          '#processing-msg'
        ).classList.remove('hidden');
      }
    });
  }

  get headers() {
    return { 'X-CSRF-Token': getMetaValue('csrf-token') };
  }

  get url() {
    return this.data.get('url');
  }

  get maxFiles() {
    return this.data.get('maxFiles') || 1;
  }

  get maxFileSize() {
    return this.data.get('maxFileSize') || 256;
  }

  get acceptedFiles() {
    return this.data.get('acceptedFiles');
  }

  get addRemoveLinks() {
    return this.data.get('addRemoveLinks') || true;
  }

  get uploadType() {
    return this.data.get('type');
  }

  get offering() {
    return this.data.get('oId');
  }

  get vendor() {
    return this.data.get('vId');
  }

  createDropZone(controller) {
    return new Dropzone(controller.element, {
      url: controller.url,
      headers: controller.headers,
      maxFiles: controller.maxFiles,
      maxFilesize: controller.maxFileSize,
      acceptedFiles: controller.acceptedFiles,
      addRemoveLinks: controller.addRemoveLinks,
      autoProcessQueue: true,
      autoQueue: true,
      uploadMultiple: true,
      parallelUploads: 1,
      thumbnailWidth: 150,
      thumbnailHeight: 150,
      previewTemplate: document.getElementById('tpl').innerHTML,
      dictCancelUpload: 'Cancel'
    });
  }

  showVideoDisclaimer() {
    document.getElementById('videos-disclaimer').classList.remove('hidden');
  }

  hideVideoDisclaimer() {
    document.getElementById('videos-disclaimer').classList.add('hidden');
  }

  isVideoUpload() {
    return this.uploadType === 'profile_videos';
  }

  #setPendingChanges() {
    setGlobalPendingChanges();
    this.dispatch('setPendingChanges');
  }
}
