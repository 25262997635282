import CheckboxSelectAll from 'stimulus-checkbox-select-all';
import { post } from '@rails/request.js';

export default class extends CheckboxSelectAll {
  static targets = [
    'checkbox',
    'clearFilter',
    'downloadButton',
    'filterSelectAll',
    'loadMoreBtn',
    'productSelectAll',
    'showClearFilter'
  ];

  initialize() {
    super.initialize();
    this.refresh();
    this.downloadButtonTarget.setAttribute('disabled', 'disabled');
  }

  download() {
    const data = this.checked.map((checkbox) => checkbox.value);
    const formData = new FormData();

    formData.append('ids', data);

    post(Routes.default.bulk_download_advisory_collaterals_path('json'), { body: formData });
  }

  // toggle selectAll checkbox
  toggleAll(event) {
    if (event.target.checked === true) {
      this.filterSelectAllTarget.checked = true;
      this.productSelectAllTarget.checked = true;
      this.clearFilterTarget.classList.remove('hidden');
      this.unchecked.forEach((checkbox) => {
        checkbox.checked = true;
      });
      this.toggleButton(event);
    } else {
      this.filterSelectAllTarget.checked = false;
      this.productSelectAllTarget.checked = false;

      if (this.showClearFilterTarget.value === 'false') {
        this.clearFilterTarget.classList.add('hidden');
      }
      this.checked.forEach((checkbox) => {
        checkbox.checked = false;
      });
      this.toggleButton(event);
    }
  }

  //toggle download button
  toggleButton() {
    this.downloadButtonTarget.disabled = this.unchecked.length === this.checkboxTargets.length;
    if (this.checked.length > 0 && this.unchecked.length > 0 && this.checkboxTargets.length !== this.checked.length) {
      this.filterSelectAllTarget.checked = false;
      this.productSelectAllTarget.checked = false;
    }

    if (this.showClearFilterTarget.value === 'false' && !this.filterSelectAllTarget.checked) {
      this.clearFilterTarget.classList.add('hidden');
    }
  }
}
