import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'button',
    'view'
  ];

  toggle(event) {
    this.buttonTargets.forEach(button => {
      button.setAttribute('aria-pressed', 'false');
    });

    //toggle styles on the grid/list toggle buttons and on the grid/list view
    event.currentTarget.setAttribute('aria-pressed', 'true');
    this.viewTarget.className = `layout--${event.params.layout}`;
  }
}
