export default class Validator {
  constructor(validationMap, errorClass = 'aria-invalid:border-danger-500') {
    this.errorClass = errorClass;
    this.validationMap = validationMap || {}; // {'FIELD_ID': ['METHOD', 'Error message. ',...]}
  }

  validate() {
    let passed = true;

    for (let key in this.validationMap) {
      let value = document.getElementById(key).value;
      let validation = this.validationMap[key];
      let multiValidation = (typeof(validation[0]) === 'string') ? false : true;

      if (multiValidation) {
        for (let validation_index = 0; validation_index < validation.length; validation_index++) {
          let val = validation[validation_index];
          if (!this.testValue(val, value, key)) {
            passed = false;
          }
        }
      } else {
        if (!this.testValue(validation, value, key)) {
          passed = false;
        }
      }
    }

    return passed;
  }

  testValue = function(validation, value, key) {
    let method = validation[0];
    let error_msg = validation[1];
    let params = (validation.length > 2) ? validation.slice(2, validation.length) : null;
    let passed = true;

    if (params === null) {
      passed = this[method](value.replace(/(\'|\"|\`)/gim, ""));
    } else {
      passed = this[method](value.replace(/(\'|\"|\`)/gim, ""), params);
    }

    if (passed) {
      this.markAsGood(key);
    } else {
      this.removeWarnings(key); // This will only allow 1 error per time
      this.markAsError(key, error_msg);
    }

    return passed;
  }

  validURL = function (value) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return pattern.test(value);
  }

  validURLOrEmpty = function (value) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return pattern.test(value) || value === "";
  }

  validTwitterHandleOrEmpty = function(value) {
    let patternURL = /^(?:https?:\/\/)?(?:www\.)?twitter\.com\//g;
    let patternHandle = new RegExp('^@?[a-zA-Z0-9_]{3,15}$');
    let sanitizedValue = value.replace(patternURL, "");

    return patternHandle.test(sanitizedValue) || value === "";
  }

  notEmpty = function (value) {
    return value !== '' && value !== null && value !== undefined
  }

  greaterThan = function (value, params) {
    let gt = parseInt(params);
    return parseInt(value) > gt;
  }

  generateError = function(id = '', msg = `There's an error.`) {
    const errorClasses = ['flex', 'mt-1', 'text-danger-500', 'text-xs'];
    const errorLabel = document.createElement('span');

    errorLabel.classList.add(...errorClasses);
    errorLabel.innerText = msg;
    errorLabel.setAttribute('id', `${id}-error`);
    errorLabel.setAttribute('role', 'alert');
    errorLabel.setAttribute('aria-atomic', 'true');
    errorLabel.setAttribute('aria-live', 'assertive');

    return errorLabel;
  }

  removeWarnings = function (id) {
    const el = document.getElementById(id);

    el.removeAttribute('aria-invalid');
    el.removeAttribute('aria-errormessage');
    document.getElementById(`${id}-error`)?.remove();
  }

  markAsError = function (id, reason) {
    let el = document.getElementById(id);

    el.parentElement.parentElement.appendChild(this.generateError(id, reason));
    el.classList.add(this.errorClass);
    el.setAttribute('aria-invalid', 'true');
    el.setAttribute('aria-errormessage', `${id}-error`);
  }

  markAsGood = function (id) {
    this.removeWarnings(id);
  }
};
