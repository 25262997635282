import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    slug: String,
    primaryTaxonomy: String,
    secondaryTaxonomies: Array
  }

  static targets = [ 'title', 'line' ];

  taxonomies = Object.freeze({
    'ar-pr-and-comms': 'AR, PR & Comms',
    'branding-and-creative': 'Branding & Creative',
    'customer-experience': 'Customer Experience',
    'digital-and-field-marketing': 'Digital & Field Marketing',
    'marketing-operations': 'Marketing Operations',
    'strategy-and-leadership': 'Strategy & Leadership',
    'people-and-resources': 'People & Resources',
    'product-management': 'Product Management',
    'product-marketing': 'Product Marketing',
    'sales-effectiveness': 'Sales Effectiveness'
  });

  connect() {
    this.#setClasses(this.primaryTaxonomyValue);
  }

  updateSlug(slug) {
    if (this.slugValue !== slug) {
      this.#removeClasses(this.slugValue);
      this.#setClasses(slug);
      this.slugValue = slug;
      this.titleTarget.innerText = this.taxonomies[slug];
    }
  }

  resetToPrimary() {
    this.updateSlug(this.primaryTaxonomyValue);
  }

  currentSlugIsPrimary() {
    return this.slugValue === this.primaryTaxonomyValue;
  }

  #setClasses(slug) {
    this.titleTarget.classList.add(`text-${slug}-700`);
    this.lineTarget.classList.add(`bg-${slug}-500`);
  }

  #removeClasses(slug) {
    this.titleTarget.classList.remove(`text-${slug}-700`);
    this.lineTarget.classList.remove(`bg-${slug}-500`);
  }
}
