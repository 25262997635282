import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    sortable: Boolean
  }

  static targets = [
    'container',
    'submitButton'
  ];

  initialize() {
    this.focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

    this.keys = {
      tab: 9,
      esc: 27
    }
  }

  closeFromSaveDraft({ detail: { message: { id: id } } }) {
    this.closeModal(id);
  }

  close({ params: { id: id }}) {
    this.closeModal(id);
    this.enableSubmit();
    if (this.hasSortableValue) {
      this.element.dispatchEvent(new CustomEvent('unlockSortable', {
        bubbles: true
      }));
      this.element.dispatchEvent(new CustomEvent('refreshSortable', {
        bubbles: true
      }));
    }
  }

  saveSortableDraft({ params: { id: id }}) {
    this.element.dispatchEvent(new CustomEvent('saveSortableDraft', {
      detail: {
        message: {
          id: id
        }
      },
      bubbles: true
    }));
  }

  closeModal(id) {
    document.getElementById(id).classList.add('hidden');
    this.resetFocus(event);
    document.querySelector('body').classList.remove('overflow-hidden');
  }

  documentOverflow() {
    document.querySelector('body').classList.toggle('overflow-hidden');
  }

  modalFocus(id) {
    document.getElementById(`${id}__close`).focus();
  }

  termsModalFocus(event) {
    document.getElementById('terms_and_conditions_accepted').focus();
  }

  keydownEvent(event) {
    switch (event.keyCode) {
      case this.keys.tab:
        this.trapFocus(event);
        break;
      case this.keys.esc:
        this.close(event);
        break;
    }
  }

  acceptTerms(event) {
    const terms = document.querySelector('#terms_and_conditions_accepted');
    if (terms.checked) {
      document.getElementById(event.params.id).classList.add('hidden');
      document.getElementById("submit-terms").click();
      this.documentOverflow();
    } else {
      alert('You must accept the terms and conditions');
    }
  }

  open(event) {
    let modalId = false;
    let resetEl = document.getElementById('main');

    if (event.params?.id) { // Triggered by event (button click)
      modalId = event.params.id;
      resetEl = event.target;
    } else if (event.id) { // Triggered by self (delay)
      modalId = event.id;
    } else if (document.getElementById('consent-modal--confirm') && !document.getElementById('advisory-terms')) { // Consent modal specific
      modalId = 'consent-modal--confirm';
    }

    // Open modal
    if (modalId) {
      this.resetFocus.element = resetEl;
      document.getElementById(modalId).classList.remove('hidden');
      this.modalFocus(modalId);
      this.documentOverflow();
    }
  }

  resetFocus() {
    if (this.resetFocus.element) {
      this.resetFocus.element.focus();
    }
  }

  trapFocus(event) {
    const modal = document.getElementById(event.params.id);
    const focusableContent = modal.querySelectorAll(this.focusableElements);
    const firstFocusableElement = focusableContent[0];
    const lastFocusableElement = focusableContent[focusableContent.length - 1];

    if (event.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        event.preventDefault();
      }
    } else {
      if (document.activeElement === lastFocusableElement) {
        firstFocusableElement.focus();
        event.preventDefault();
      }
    }
  }

  enableSubmit() {
    if (this.hasSubmitButtonTarget && this.submitButtonTarget.disabled) {
      this.submitButtonTarget.disabled = false;
    }
  }

  disableSubmit() {
    if (this.hasSubmitButtonTarget && !this.submitButtonTarget.disabled) {
      this.submitButtonTarget.disabled = true;
    }
  }
}
