export default class CountryStateSelect {
  /**
   * constructor
   * Set the ids of country and state selects
   * @param  {[string]} country_selector [id of the country select]
   * @param  {[string]} state_selector [id of the state select]
   */
  constructor(country_selector = 'country', state_selector = 'state') {
    this.country_selector = country_selector;
    this.state_selector = state_selector;
  }

  /**
   * selectState description
   * Set the ids of country and state selects
   */
  selectState() {
    let element = document.getElementById('country');
    let countryCode = element.options[element.selectedIndex].dataset['countryCode'];
    postJSON( Routes.default.find_states_path(), {
        country_id: countryCode
    })
    .then(data => {
      // sort data by first field
      data.sort((a, b) => {
        return (a[1] < b[1])
      });
      let select = document.getElementById(this.state_selector);
      let selected = document.getElementById(this.state_selector).dataset.selected;
      select.innerHTML = '';
      data.map(function(val, index) {
        let option = document.createElement('option');
        option.text = val[1];
        option.value = val[1];
        if (selected === option.value) {
          option.selected = 'selected';
        }
        select.appendChild(option);
      });
    });
  }
}

// Create a AJAX request easily)
async function postJSON(url = '', data = {}, mode = 'cors', method = 'POST') {
  const response = await fetch(url, {
    method: method,
    mode: mode,
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    body: JSON.stringify(data)
    });
  return response.json(); // parses JSON response into native JavaScript objects
}

/*

Steps to set up

1. Instantiate an object with the  id of the select fields. (defaults to 'country', 'state')
window.countryState = new CountryStateSelect();

2. Call selectState().

*/
