import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';
import * as Routes from '../routes';
import { Turbo } from '@hotwired/turbo-rails';
import { 
  requestUpdateDraggable,
  setGlobalPendingChanges
} from '../helpers';

const URL_REGEX = /https:\/\/www\.youtube\.com\/watch\?v=(.+)|https:\/\/youtu\.be\/(.+)/;

TYPE_SOCIAL_VIDEO = 'social_video';

export default class extends Controller {
  static targets = ['url', 'spinner', 'removeSpinner', 'error', 'button'];

  static outlets = ['sortable', 'asset-sortable', 'dropzone'];

  static values = {
    offeringId: Number,
    vendorId: Number,
    maxVideos: Number
  }

  connect() {
    this.#validateAddButton();
    if (this.maxVideosValue === 0) { this.#disable() };
  }

  save() {
    this.#showSpinner();
    this.#removeErrorMessage();
    if (this.#validateUrl()) {
      const formData = new FormData();
      formData.append('url', this.urlTarget.value);
      formData.append('offering_id', this.offeringIdValue);
      formData.append('vendor_id', this.vendorIdValue);
      this.#callController(formData);
    } else {
      this.#addErrorMessage();
    }
    this.#hideSpinner();
  }

  onInput() {
    this.#validateAddButton();
  }

  #callController(formData) {
    post(Routes.vendor_offering_social_videos_path(this.vendorIdValue, this.offeringIdValue), {
      body: formData,
      responseKind: 'application/json'
    })
    .then((response) => {
      if (response.ok === true) {
        response.json.then((content) => {
          this.sortableOutlet.totalCountValue++;
          requestUpdateDraggable(content.id, TYPE_SOCIAL_VIDEO, this.sortableOutlet.totalCountValue, this.sortableOutlet.deletedCountValue, this.vendorIdValue, this.offeringIdValue);
          this.dropzoneOutlet.reduceMaxFiles();
          this.reduceMaxVideos();
          this.#setPendingChanges();
        });
        this.#clearUrlInput();
      } else {
        this.#addErrorMessage();
      }
    });
  }

  #validateUrl() {
    return URL_REGEX.test(this.urlTarget.value);
  }

  #showSpinner() {
    this.spinnerTarget.classList.remove('hidden');
  }

  #hideSpinner() {
    this.spinnerTarget.classList.add('hidden');
  }

  #addErrorMessage() {
    this.errorTarget.classList.remove('hidden');
  }

  #removeErrorMessage() {
    this.errorTarget.classList.add('hidden');
  }

  #clearUrlInput() {
    this.urlTarget.value = '';
  }

  #disable() {
    this.urlTarget.disabled = true;
    this.buttonTarget.disabled = true;
  }

  #validateAddButton() {
    this.buttonTarget.disabled = this.#validateUrl() === false;
  }

  reduceMaxVideos() {
    this.maxVideosValue--;
    if (this.maxVideosValue === 0) this.#disable();
  }

  #setPendingChanges() {
    setGlobalPendingChanges();
    this.dispatch('setPendingChanges');
  }
}
