import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    formId: String
  };

  #resetCached() {
    this.element.querySelectorAll('div.duet-date').forEach((e) => { e.parentElement.removeChild(e) });
  }

  disconnect() {
    document.removeEventListener('turbo:before-cache', this.#resetCached());
    this.#resetCached();
    super.disconnect();
  }

  connect() {
    document.addEventListener('turbo:before-cache', this.#resetCached());

    super.connect();

    const DATE_FORMAT_US = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;

    this.element.dateAdapter = {
      parse(value = "", createDate) {
        const matches = value.match(DATE_FORMAT_US);

        if (matches) {
          return createDate(matches[3], matches[1], matches[2]);
        }
      },
      format(date) {
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      },
    };

    this.element.addEventListener('duetChange', function(e) {
      const formId = e.target.dataset.datepickerFormIdValue;
      const form = document.getElementById(formId);
      form?.requestSubmit();
    });

    this.element.localization = {
      buttonLabel: 'Choose date',
      placeholder: 'mm/dd/yyyy',
      selectedDateMessage: 'Selected date is',
      prevMonthLabel: 'Previous month',
      nextMonthLabel: 'Next month',
      monthSelectLabel: 'Month',
      yearSelectLabel: 'Year',
      closeLabel: 'Close window',
      calendarHeading: 'Choose a date',
      dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      locale: 'en-US',
    }
  }
}
