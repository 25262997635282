import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'button',
    'listbox',
    'option'
  ];

  initialize() {
    this.activeDescendant = this.listboxTarget.getAttribute('aria-activedescendant');
    this.multiselectable = this.listboxTarget.getAttribute('aria-multiselectable') === 'true' ? true : false;
    this.focusStyle = ['bg-gray-200'];
    this.keyCode = {
      RETURN: 13,
      ESC: 27,
      SPACE: 32,
      END: 35,
      HOME: 36,
      UP: 38,
      DOWN: 40
    }

    this.setupFocus();
    this.hideListbox();
  }

  addFocusStyle(element) {
    element.classList.add(...this.focusStyle);
  }

  // Check if an item is clicked on. If so, focus on it and select it.
  checkClickItem(event) {
    this.clearFocusStyle();
    this.focusItem(event.currentTarget);
    this.toggleSelectItem(event.currentTarget);
    this.hideListbox();
  }

  // Handle various keyboard controls; UP/DOWN will shift focus; SPACE selects an item.
  checkKeyPress() {
    const key = event.which || event.keyCode;
    let nextItem = document.getElementById(this.activeDescendant);

    if (!nextItem) {
      return;
    }

    switch (key) {
      case this.keyCode.UP:
      case this.keyCode.DOWN:
        event.preventDefault();

        if (key === this.keyCode.UP) {
          nextItem = nextItem.previousElementSibling;
        } else {
          nextItem = nextItem.nextElementSibling;
        }

        if (nextItem) {
          this.clearFocusStyle();
          this.focusItem(nextItem);
        }

        break;
      case this.keyCode.HOME:
        event.preventDefault();
        this.focusFirstItem();
        break;
      case this.keyCode.END:
        event.preventDefault();
        this.focusLastItem();
        break;
      case this.keyCode.SPACE:
        event.preventDefault();
        this.toggleSelectItem(nextItem);
        break;
      case this.keyCode.RETURN:
        this.toggleSelectItem(nextItem);
        this.hideListbox();
        break;
      default:
        return null;
    }
  }

  checkHide(event) {
    const key = event.which || event.keyCode;

    switch (key) {
      case this.keyCode.RETURN:
      case this.keyCode.ESC:
        event.preventDefault();
        this.hideListbox();
        break;
      default:
        return null;
    }
  }

  checkShow(event) {
    const key = event.which || event.keyCode;

    switch (key) {
      case this.keyCode.UP:
      case this.keyCode.DOWN:
        event.preventDefault();
        this.showListbox();
        this.checkKeyPress(event);
        break;
      default:
        return null;
    }
  }

  clearFocusStyle() {
    this.optionTargets.forEach(option => {
      option.classList.remove(...this.focusStyle);
    });
  }

  focusFirstItem() {
    const firstItem  = this.listboxTarget.querySelector('[role="option"]');

    if (firstItem) {
      this.focusItem(firstItem);
    }
  }

  focusItem(element) {
    this.addFocusStyle(element);

    this.listboxTarget.setAttribute('aria-activedescendant', element.id);
    this.activeDescendant = element.id;

    if (this.listboxTarget.scrollHeight > this.listboxTarget.clientHeight) {
      const scrollBottom = this.listboxTarget.clientHeight + this.listboxTarget.scrollTop;
      const elementBottom = element.offsetTop + element.offsetHeight;

      if (elementBottom > scrollBottom) {
        this.listboxTarget.scrollTop = elementBottom - this.listboxTarget.clientHeight;
      } else if (element.offsetTop < this.listboxTarget.scrollTop) {
        this.listboxTarget.scrollTop = element.offsetTop;
      }
    }
  }

  focusLastItem() {
    const itemList = this.listboxTarget.querySelectorAll('[role="option"]');

    if (itemList.length) {
      this.focusItem(itemList[itemList.length - 1]);
    }
  }

  hideListbox() {
    this.buttonTarget.setAttribute('aria-expanded', 'false');
    this.buttonTarget.focus();

    this.setButtonText();
  }

  onFocusChange(focusedItem) {
    this.button.innerText = focusedItem.innerText;
  }

  setButtonText() {
    let optionsSelected = 0;

    this.optionTargets.forEach(option => {
      if (option.getAttribute('aria-selected') === 'true') {
        optionsSelected += 1;
      }
    });

    // Change button text
    if (optionsSelected === 1) {
      this.buttonTarget.querySelector('span').innerHTML = this.listboxTarget.querySelector('[aria-selected="true"]').innerHTML;
    } else if (optionsSelected > 0) {
      this.buttonTarget.querySelector('span').innerHTML = `${optionsSelected} selected`;
    }
  }

  // If there is no activeDescendant, focus on the first option
  setupFocus() {
    if (this.activeDescendant) {
      return;
    }

    this.focusFirstItem();
  }

  showListbox() {
    this.buttonTarget.setAttribute('aria-expanded', 'true');
    this.listboxTarget.focus();
  }

  // Toggle the aria-selected value
  toggleSelectItem(element) {
    if (!this.multiselectable) {
      this.optionTargets.forEach(option => {
        option.setAttribute('aria-selected', 'false');
      });
    }

    element.setAttribute('aria-selected', element.getAttribute('aria-selected') === 'true' ? 'false' : 'true');
  }
}
