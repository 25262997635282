import { Controller } from "@hotwired/stimulus";
import { post, put } from '@rails/request.js';

import {
  setGlobalPendingChanges,
  truncateToWords
} from '../helpers';

import * as Routes from '../routes';

export default class extends Controller {
  static targets = [
    'titleCard',
    'descriptionCard',
    'modal',
    'pendingMessage',
    'titleForm',
    'titleFormEdit',
    'descriptionForm',
    'descriptionFormEdit',
    'order',
    'index',
    'destroy',
    'customThumbnail',
    'showOrder',
    'published',
    'thumbnailDraftId',
    'thumbnailDraftDestroy',
    'thumbnailPublishedId',
    'thumbnailPublishedDestroy'
  ];

  static values = { 
    attachmentType: String,
    attachmentId: Number,
    vendorId: Number,
    offeringId: Number,
    pendingChanges: Boolean
  }

  connect() {
    if (this.hasTitleFormEditTarget) {
      this.titleFormEditTarget.addEventListener('keyup', () => {
        let length = this.titleFormEditTarget.value.length

        if (length === 0) {
          this.modalTarget.dispatchEvent(new CustomEvent('disableSubmitButton', {
            bubbles: true
          }));
        } else {
          this.modalTarget.dispatchEvent(new CustomEvent('enableSubmitButton', {
            bubbles: true
          }));
        }
      });
    }
    if (this.pendingChangesValue === true) {
      this.setPendingMessages();
    }
  }

  disconnect() {
    if (this.hasTitleFormEditTarget) {
      this.titleFormEditTarget.removeEventListener('keyup', event);
    }
  }

  saveDraft({ detail: { message: message } }) {
    let title = this.titleFormEditTarget.value;
    let description = this.descriptionFormEditTarget.value;
    if (this.titleFormTarget.value != title || this.descriptionFormTarget.value != description) {
      this.titleFormTarget.value = title;
      this.descriptionFormTarget.value = description;
      this.titleCardTarget.textContent = title;
      this.descriptionCardTarget.textContent = truncateToWords(description, 10);
      this.setPendingMessages();
      this.#callAssetDraftValuesController();
    }
  }

  setFormFields() {
    this.titleFormEditTarget.value = this.titleFormTarget.value;
    this.descriptionFormEditTarget.value = this.descriptionFormTarget.value;
  }

  lockBackground() {
    this.element.classList.remove('js-sortable');
  }

  unlockBackground() {
    this.element.classList.add('js-sortable');
  }

  refresh() {
    if (this.hasCustomThumbnailTarget) {
      let customThumbnail = this.application.getControllerForElementAndIdentifier(
        this.customThumbnailTarget,
        'custom-thumbnail'
      );
      customThumbnail.reset();
    }
  }

  setPendingMessages() {
    this.pendingMessageTarget.classList.remove('hidden');
    this.dispatch('setPendingChanges');
    setGlobalPendingChanges(); 
  }

  #callAssetDraftValuesController() {
    const formData = new FormData();
    formData.append('draft_title', this.titleFormTarget.value);
    formData.append('draft_description', this.descriptionFormTarget.value);
    formData.append('attachment_type', this.attachmentTypeValue);

    put(Routes.update_draft_values_vendor_offering_attachment_path(this.vendorIdValue, this.offeringIdValue, this.attachmentIdValue), {
      body: formData,
      responseKind: 'json'
    });
  }
}
