import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'animationTrigger'
  ];

  initialize() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.querySelectorAll('path').forEach(path => {
            path.classList.add(entry.target.dataset.scrollAnimateClassValue);
          });
        }
      });
    });

    this.animationTriggerTargets.forEach(trigger => {
      observer.observe(trigger);
    });
  }
}
