import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [
    'item',
    'itemTitle',
    'trigger',
    'triggerTitle'
  ];

  initialize() {
    // Populate accordion titles from child items
    this.itemTitleTargets.forEach((title, index) => {
      this.triggerTitleTargets[index].innerText = title.innerHTML;
    });
  }

  // Keyboard behavior on accordion triggers
  itemSelect(event) {
    const key = event.which.toString();
    const length = this.triggerTargets.length;
    const ctrlModifier = (event.ctrlKey && key.match(/33|34/)); // 33 = Page Up, 34 = Page Down

    // Up/Down arrow and Control + Page Up/Page Down keyboard operations
    if (key.match(/38|40/) || ctrlModifier) { // 38 = Up, 40 = Down
      const index = this.triggerTargets.indexOf(event.target);
      const direction = (key.match(/34|40/)) ? 1 : -1;
      const newIndex = (index + length + direction) % length;

      this.triggerTargets[newIndex].focus();

      event.preventDefault();
    } else if (key.match(/35|36/)) { // 36 = Home, 35 = End
      switch (key) {
        // Go to first accordion
        case '36':
        this.triggerTargets[0].focus();
          break;
        // Go to last accordion
        case '35':
          this.triggerTargets[length - 1].focus();
          break;
      }

      event.preventDefault();
    }
  }

  toggle(event) {
    event.stopPropagation();

    // Check if the current toggle is expanded.
    const isExpanded = event.currentTarget.getAttribute('aria-expanded') === 'true';
    const item = event.currentTarget.getAttribute('aria-controls');

    // Close all items
    this.triggerTargets.forEach(trigger => {
      trigger.setAttribute('aria-expanded', 'false')
    });

    this.itemTargets.forEach(item => {
      item.classList.add('hidden');
    });

    // Open item if it wasn't just closed
    if (!isExpanded) {
      event.currentTarget.setAttribute('aria-expanded', 'true');
      document.getElementById(item).classList.toggle('hidden');
    }
  }
}
