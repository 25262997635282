import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = [
    'toggleTrigger'
  ];

  get controls() {
    return this.element.getAttribute('aria-controls');
  }

  get expanded() {
    return this.element.getAttribute('aria-expanded');
  }

  display() {
    event.stopPropagation();

    document.getElementById(this.controls).classList.toggle('hidden');

    this.element.setAttribute('aria-expanded', this.expanded === 'true' ? 'false' : 'true');
  }

  hideAll() {
    this.toggleTriggerTargets.forEach(trigger => {
      trigger.setAttribute('aria-expanded', 'false');
      document.getElementById(trigger.getAttribute('aria-controls')).classList.add('hidden');
    });
  }

  hideAllCheck() {
    const controls = this.toggleTriggerTarget.getAttribute('aria-controls');
    const controlsEl = document.querySelector(`#${controls}`);

    if (!controlsEl.contains(event.target)) {
      this.hideAll();
    }
  }
}
