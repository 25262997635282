import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['spinner'];

  addSpinner() {
    this.spinnerTarget.classList.add('fa', 'fa-circle-o-notch', 'fa-spin');
  }

  removeSpinner() {
    this.spinnerTarget.classList.remove('fa', 'fa-circle-o-notch', 'fa-spin');
  }
}
