import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { canDownload: Boolean, defaultUrl: String };

	connect() {
		this.replaceLinks();
	}

	replaceLinks() {
		const links = document.getElementsByTagName('a');
		const regex = /(?:www\.)?infotech\.com\/download\//;

		for (let i = 0; i < links.length; i++) {
			const link = links[i];
			const oldHref = link.getAttribute('href');
			const newUrl = this.defaultUrlValue + '/download/';

			if (regex.test(oldHref)) {
				let newHref = Routes.default.new_user_session_path();
				if (this.canDownloadValue) {
					newHref = oldHref.replace(regex, newUrl) + '.html';
				}
				link.setAttribute('href', newHref);
				link.setAttribute('target', '_blank');
			}
		}
	}
}
