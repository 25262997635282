import { Controller } from '@hotwired/stimulus';
import { getMetaValue } from '../shared/helpers';

import * as Routes from '../routes';

export default class extends Controller {
  static values = {
    class: String,
    field: String,
    param: String,
    type: String
  }

  log() {
    const formData = new FormData();
    formData.append('class', this.classValue);
    formData.append('field', this.fieldValue);
    formData.append('param', this.paramValue);
    formData.append('type', this.typeValue);
    fetch(Routes.user_action_logger_path(), {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': getMetaValue('csrf-token')
      },
      body: formData
    })
  }
}
