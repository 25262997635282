import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    delay: Number
  }

  delay() {
    setTimeout(() => {
      this.dispatch('finished');
    }, this.delayValue);
  }
}
