import Clipboard from 'stimulus-clipboard';

export default class extends Clipboard {
  copiedClass: string;
  sourceTarget: HTMLElement;

  static classes = ['copied'];

  connect (): void {
    if (!this.hasButtonTarget) return;

    this.successDuration = this.successDurationValue || 2000;
  }

  copy (event: Event): void {
    event.preventDefault();
    navigator.clipboard.writeText(this.sourceTarget.getAttribute('href'));
    this.copied();
  }

  copied (): void {
    if (!this.hasButtonTarget) return;

    if (this.timeout) { clearTimeout(this.timeout) };

    this.buttonTarget.classList.replace('fa-copy', this.copiedClass);

    this.timeout = setTimeout(() => {
      this.buttonTarget.classList.replace(this.copiedClass, 'fa-copy');
    }, this.successDuration);
  }
}
