import { Controller } from "@hotwired/stimulus";
import { getMetaValue } from '../shared/helpers';
import { FetchRequest } from '@rails/request.js';
import { post } from '@rails/request.js';
import { put } from '@rails/request.js';

import * as Routes from '../routes';

const EDIT_FILTER = Object.freeze({
  NO_FILTER: 0,
  REMOVE_DRAFT: 1,
  REMOVE_PUBLISHED: 2
});

const MIN_WIDTH = 300;
const MIN_HEIGTH = 169;
const MAX_FILE_SIZE = 500;
const MBYTE = 1024;

export default class extends Controller {
  static targets = ['input', 'removeSpinner', 'addSpinner', 'error'];
  static values = {
    attachmentId: Number,
    attachmentType: String,
    offeringId: Number,
    vendorId: Number,
    draftId: Number,
    publishedId: Number,
    draftDestroy: Boolean,
    publishedDestroy: Boolean
  }
  static outlets = ['asset-sortable'];

  assetSortableOutletConnected() {
    this.draftIdValue = this.assetSortableOutlet.thumbnailDraftIdTarget.value;
    this.publishedIdValue = this.assetSortableOutlet.thumbnailPublishedIdTarget.value;
    this.draftDestroyValue = this.assetSortableOutlet.thumbnailDraftDestroyTarget.value === 'true';
    this.publishedDestroyValue = this.assetSortableOutlet.thumbnailPublishedDestroyTarget.value === 'true';

    if (this.draftIdValue) this.assetSortableOutlet.setPendingMessages();
  }

  upload() {
    this.#addSpinner(this.addSpinnerTarget);
    let file = this.inputTarget.files[0];

    if (this.#isInvalidFile(file)) {
      this.displayValidationError();
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('content_type', file.type);
    formData.append('file_name', file.name);
    this.callUploadController(formData);
  }

  remove() {
    this.#addSpinner(this.removeSpinnerTarget);
    this.#callRemoveController(this.#determineRemove());
    const formData = new FormData();
    formData.append('attachment_id', this.attachmentIdValue);
    formData.append('filter', this.#calculateNextRemoveFilter());
    formData.append('type', this.attachmentTypeValue);
    formData.append('status', 'ok');

    this.#callRefreshController(formData);
  }

  async callUploadController(formData) {
    let response = await post(Routes.vendor_offering_attachment_attachment_custom_thumbnails_path(this.vendorIdValue, this.offeringIdValue, this.attachmentIdValue), {
      body: formData,
      responseKind: 'application/json'
    })
    const formDataRefresh = new FormData();
    formDataRefresh.append('attachment_id', this.attachmentIdValue);
    formDataRefresh.append('type', this.attachmentTypeValue);

    if (response.ok === true) {
      let content = await response.json;

      if (content.id !== undefined) {
        this.assetSortableOutlet.thumbnailDraftIdTarget.value = content.id;
        this.draftIdValue = content.id;
  
        if (this.draftDestroyValue === true) {
          this.assetSortableOutlet.thumbnailDraftDestroyTarget.value = 'false';
          this.draftDestroyValue = false;
        }

        formDataRefresh.append('status', 'ok');
        formDataRefresh.append('filter', this.#calculateNextRemoveFilter());
      } else {
        formDataRefresh.append('status', 'error');
        formDataRefresh.append('show_validation_error', true);
      }
    } else {
      formDataRefresh.append('status', 'error');
    }

    this.#callRefreshController(formDataRefresh);
  }

  #callRefreshController(formData) {
    post(Routes.refresh_custom_thumbnail_vendor_offering_attachment_path(this.vendorIdValue, this.offeringIdValue, this.attachmentIdValue), {
      body: formData,
      responseKind: 'turbo-stream'
    });
    this.assetSortableOutlet.setPendingMessages();
  }

  #callRemoveController(thumbnailId) {
    put(Routes.destroy_if_draft_vendor_offering_attachment_attachment_custom_thumbnail_path(this.vendorIdValue, this.offeringIdValue, this.attachmentIdValue, thumbnailId), {
      responseKind: 'json'
    });
  }

  #addSpinner(target) {
    target.classList.add('fa', 'fa-circle-o-notch', 'fa-spin');
  }

  reset() {
    this.errorTarget.classList.add('hidden');
  }

  #determineRemove() {
    if (this.draftIdValue > 0 && this.draftDestroyValue === false) {
      this.assetSortableOutlet.thumbnailDraftDestroyTarget.value = 'true';
      this.draftDestroyValue = true;
      return this.draftIdValue;
    } else if (this.publishedIdValue > 0 && this.publishedDestroyValue === false) {
      this.assetSortableOutlet.thumbnailPublishedDestroyTarget.value = 'true';
      this.publishedDestroyValue = true;
      return this.publishedIdValue;
    }
  }

  #calculateNextRemoveFilter() {
    if (this.draftIdValue > 0) {
      if (this.draftDestroyValue === true) {
        if (this.publishedDestroyValue === true) {
          return EDIT_FILTER.REMOVE_PUBLISHED;
        } else {
          return EDIT_FILTER.REMOVE_DRAFT;
        }
      } else {
        return EDIT_FILTER.NO_FILTER;
      }
    } else {
      if (this.publishedIdValue > 0) {
        return EDIT_FILTER.REMOVE_PUBLISHED;
      }
    }
  }

  displayValidationError() {
    const formDataRefresh = new FormData();
    formDataRefresh.append('status', 'error');
    formDataRefresh.append('show_validation_error', true);
    formDataRefresh.append('type', this.attachmentTypeValue);

    this.#callRefreshController(formDataRefresh);
  }

  #isInvalidFile(file) {
    return !this.#isValidFile(file);
  }

  #isValidFile(file) {
    let kbytes = file.size / MBYTE;
    if (kbytes > MAX_FILE_SIZE) return false;
    if (this.#isImageFile(file.name)) {
      let img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        if (img.width < MIN_WIDTH || img.height < MIN_HEIGTH) return false;
      }
    }
    return true;
  }

  #isImageFile(filename) {
    let extension = filename.split('.').pop().toUpperCase();
    return ['PNG', 'JPG', 'JPEG'].includes(extension);
  }
}
