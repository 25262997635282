import { get, post } from '@rails/request.js';
import * as Routes from '../routes';

export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute('content');
  }
}

export function findElement(root, selector) {
  if (typeof root == 'string') {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

export function setGlobalPendingChanges(){
  const pendingBanner = document.getElementById('pending-changes');
  const pendingButton = document.getElementById('pending-changes-button');
  const savedBanner = document.getElementById('saved-changes');

  if (pendingBanner.classList.contains('hidden')) {
    pendingBanner.classList.remove('hidden');
  }
  if (pendingButton.classList.contains('hidden')) {
    pendingButton.classList.remove('hidden');
  }
  if (savedBanner != null && !savedBanner.classList.contains('hidden')) {
    savedBanner.classList.add('hidden');
  }
}

export function truncateToWords(str, numWords) {
  let words = str.split(" ");
  if (words.length > numWords) {
    return `${words.splice(0, numWords).join(" ")}...`;
  } else {
    return str;
  }
}

export function requestUpdateDraggable(attachmentId, type, length, deletedCount, vendorId, offeringId) {
  let data = {
    type: type,
    index: length,
    deleted_count: deletedCount
  }

  post(Routes.refresh_vendor_offering_attachment_path(vendorId, offeringId, attachmentId),
       { body: data, responseKind: 'turbo-stream' });
}
