import { Controller } from "@hotwired/stimulus";
import { getMetaValue } from '../shared/helpers';

import * as Routes from '../routes';

export default class extends Controller {
  checkState(e) {
    const formData = new FormData();
    formData.append('email', e.target.value)
    fetch(Routes.state_users_path(), {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': getMetaValue('csrf-token')
      },
      body: formData
    })
    .then((response) => response.json())
    .then((data) => {
      let registered = data['state'];
      document.getElementById('new_lead').classList.toggle('hidden', !registered);
      document.getElementById('request-submit').disabled = registered;
      document.getElementById('request-submit').classList.toggle('opacity-50', registered);
    });
  }
}
