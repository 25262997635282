import { Controller } from '@hotwired/stimulus';
import { Turbo } from "@hotwired/turbo-rails";
import { get, put } from '@rails/request.js';
import * as Routes from '../../routes';

import {
  setGlobalPendingChanges
} from '../../helpers';

export default class extends Controller {
  static targets = ['editMode', 'offering', 'vendor', 'description', 'editDescription', 'cancelModal'];

  static values = {
    pendingChanges: Boolean
  }

  connect() {
    if (this.pendingChangesValue === true) setGlobalPendingChanges();
  }

  setPendingChanges() {
    this.pendingChangesValue = true;
  }

  async selectOffering() {
    get(`${this.vendorTarget.value}/offerings/${this.offeringTarget.value}`, {
      responseKind: 'turbo-stream'
    });
  }

  editMode() {
    this.editModeTargets.forEach((target) => {
      target.classList.toggle('hidden');
    });
    this.editDescriptionTarget.value = this.descriptionTarget.innerHTML;
  }

  discardChanges() {
    this.revertChangesController();
    this.pendingChangesValue = false;
  }

  async revertChangesController() {
    Turbo.navigator.delegate.adapter.showProgressBar();

    await put(Routes.discard_changes_vendor_offering_attachments_path(this.vendorTarget.value, this.offeringTarget.value), {
      responseKind: 'turbo-stream'
    });

    Turbo.navigator.delegate.adapter.progressBar.hide();
  }

  cancel() {
    if (this.pendingChangesValue === true) {
      this.dispatch('cancel');
    } else {
      window.location.href = Routes.profile_vendors_path();
    }
  }
}
