import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'container',
    'filterBar',
    'filters',
    'item',
    'previous',
    'next',
    'scroll',
    'tile'
  ];

  static values = {
    primaryTaxonomy: String,
    secondaryTaxonomies: Array
  };

  initialize() {
    this.filters = [];
    this.layout();
  }

  filter(event) {
    // Active filter item, build filters
    if (event.currentTarget.ariaPressed === 'true') {
      const index = this.filters.findIndex((i) => i.taxonomy === event.params.taxonomy);

      event.currentTarget.setAttribute('aria-pressed', 'false');

      if (index !== -1) {
        this.filters.splice(index, 1);
      }
    } else {
      event.currentTarget.setAttribute('aria-pressed', 'true');

      this.filters.push({
        taxonomy: event.params.taxonomy,
        taxonomy_name: event.params.taxonomyName
      });
    }

    // Filtered targets that match taxonomies from filters applied
    let filteredTiles = this.tileTargets.filter((tile) => {
      let tileController = this.application.getControllerForElementAndIdentifier(tile, 'tile');
      return this.filters.some((i) => i.taxonomy === tileController.primaryTaxonomyValue || tileController.secondaryTaxonomiesValue.includes(i.taxonomy));
    });

    if (filteredTiles.length) {
      const filteredSlugs = this.filters.map(filter => filter.taxonomy);
      // Hide all tiles
      this.tileTargets.forEach((tile) => { 
        tile.hidden = true;
        let tileController = this.application.getControllerForElementAndIdentifier(tile, 'tile');
        tileController.resetToPrimary();
      });

      // Show filtered tiles
      filteredTiles.forEach((tile) => { 
        tile.hidden = false;
        let tileController = this.application.getControllerForElementAndIdentifier(tile, 'tile');
        this.#updateTile(tileController, filteredSlugs);
      });

      // Show filter bar
      this.filtersTarget.innerHTML = this.filters.map((i) => i.taxonomy_name).join(', <br class="sm:hidden">');
      this.filterBarTarget.classList.remove('hidden');
    } else {
      this.filterReset();
    }

    this.dispatch('searchAbility', { detail: { filters: this.filters } });
  }

  filterAbility({ detail: { active } }) {
    if (!active) {
      this.#filterDisable();
    }
  }

  #filterDisable() {
    this.itemTargets.forEach((item) => {
      item.querySelector('button').setAttribute('disabled', 'disabled');
    });
  }

  filterReset() {
    this.filters = [];
    this.filterBarTarget.classList.add('hidden');

    this.itemTargets.forEach((item) => {
      item.querySelector('button').removeAttribute('disabled');
      item.querySelector('button').setAttribute('aria-pressed', 'false');
    });

    this.tileTargets.forEach((tile) => {
      tile.hidden = false;
      let tileController = this.application.getControllerForElementAndIdentifier(tile, 'tile');
      tileController.resetToPrimary();
    });

    this.dispatch('searchAbility', { detail: { filters: this.filters } });
  }

  layout() {
    this.scrollDistance = this.itemTargets[0].getBoundingClientRect().width;
    this.scrollPos = 0;
    this.scrollPosOffset = this.scrollTargets[0].getBoundingClientRect().width * 2;
    this.scrollPosMin = ((this.itemTargets.length * this.scrollDistance) + this.scrollPosOffset + 1 - this.containerTargets[0].getBoundingClientRect().width) * -1;

    // Reset scroll position
    this.#scrollFilter();
  }

  previous() {
    this.#scrollFilter(1);
  }

  next() {
    this.#scrollFilter(-1)
  }

  #scrollFilter(dir) {
    // Update scroll position
    if (dir) {
      this.scrollPos = this.scrollPos + (this.scrollDistance * dir);
    }

    this.containerTarget.style.transform = `translateX(${this.scrollPos}px)`;

    // Reset buttons
    this.#scrollButtonsEnabled();

    // Disable buttons if next click would slide to empty space
    if (this.scrollPos + this.scrollDistance > 0) {
      this.previousTarget.setAttribute('disabled', 'disabled');
    }

    if (this.scrollPos - this.scrollDistance < this.scrollPosMin) {
      this.nextTarget.setAttribute('disabled', 'disabled');
    }
  }

  #scrollButtonsEnabled() {
    this.scrollTargets.forEach((target) => {
      target.removeAttribute('disabled');
    });
  }

  #updateTile(tileController, filteredSlugs) {
    if (this.#slugInFilteredSlugs(tileController.primaryTaxonomyValue, filteredSlugs) &&
        !tileController.currentSlugIsPrimary()) {
      tileController.updateSlug(tileController.primaryTaxonomyValue);
    } else if (!this.#slugInFilteredSlugs(tileController.slugValue, filteredSlugs)) {
      const secondaryTaxonomies = this.#matchingSecondaryTaxonomies(filteredSlugs, tileController);
      tileController.updateSlug(secondaryTaxonomies[0]);
    }
  }

  #slugInFilteredSlugs(slug, filteredSlugs) {
    return filteredSlugs.includes(slug);
  }

  #matchingSecondaryTaxonomies(filteredSlugs, tileController) {
    return filteredSlugs.filter((slug) => tileController.secondaryTaxonomiesValue.includes(slug));
  }
}
