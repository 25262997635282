import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'clearFilter'
  ];

  resetForm() {
    this.element.reset();
    document.querySelectorAll('duet-date-picker').forEach(e => { e.value = ''; });
    document.getElementById('q_sorts').options.selectedIndex = 2;
    this.clearFilterTarget.classList.add('hidden');
    this.element.requestSubmit();
  }
}
