import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    action: { default: 'registration', type: String }
  }

  initialize() {
    if (document.getElementById('recaptcha-verification') || typeof grecaptcha === 'undefined') return;

    let action = this.actionValue;
    let captcha = document.getElementById('g-recaptcha-response-data-registration');

    if (!captcha) return;

    grecaptcha.ready(function() {
      grecaptcha.execute(captcha.dataset.sitekey, {
        action: action
      }).then(function(token) {
        fetch(`/recaptcha/v3?action=${action}&token=${token}`);
      }).then(() => {
        captcha.remove();
      });
    });
  }
}
