import { Controller } from "@hotwired/stimulus"

const NEXT_SLIDE = 1;
const PREV_SLIDE = -1;

export default class extends Controller {
  static targets = [
    'content',
    'dot',
    'pause',
    'prev',
    'next',
    'slide',
    'slider',
  ];

  initialize() {
    this.autoplaySpeed = 5000;
    this.index = 0;
    this.paused = true;
    this.scrollDistance = 0;
    this.scrollPos = 0;
    this.timer;

    this.layout();
  }

  pause(event) {
    if (event && event.type === 'click' && this.paused) {
      this.play();
    } else {
      this.paused = true;
      this.pauseTarget.setAttribute('aria-pressed', 'true');

      clearInterval(this.timer);
    }
  }

  play() {
    this.paused = false;
    this.pauseTarget.setAttribute('aria-pressed', 'false');

    this.timer = setInterval(() => {
      if (this.index + 1 > this.slideTargets.length - 1) {
        this.index = 0;
        this.layout();
      } else {
        this.next();
      }
    }, this.autoplaySpeed);
  }

  layout() {
    // Reset layout
    this.sliderTarget.classList.remove('flex');
    this.sliderTarget.style.width = '';
    this.scrollPos = 0;
    this.index = 0;

    // Re-calc
    this.scrollDistance = this.slideTarget.getBoundingClientRect().width;
    this.sliderTarget.style.width = `${this.scrollDistance * this.slideTargets.length}px`;
    this.sliderTarget.classList.add('flex');

    this.showCurrentSlide();
  }

  disableScrollButtons() {
    if (this.index - 1 < 0) {
      this.prevTarget.setAttribute('disabled', 'disabled');
    }

    if (this.index + 1 > this.slideTargets.length - 1) {
      this.nextTarget.setAttribute('disabled', 'disabled');
    }
  }

  enableScrollButtons() {
    this.prevTarget.removeAttribute('disabled');
    this.nextTarget.removeAttribute('disabled');
  }

  next() {
    this.index++;
    this.showCurrentSlide(NEXT_SLIDE);
  }

  prev() {
    this.index--;
    this.showCurrentSlide(PREV_SLIDE);
  }

  choseSlide(event) {
    const target = event.currentTarget;
    const index = [...target.parentElement.children].indexOf(target);
    const diff = index - this.index;

    this.index = index;
    this.pause();
    this.showCurrentSlide(diff);
  }

  showCurrentSlide(dir) {
    if (dir) {
      this.scrollPos = this.scrollPos + (this.scrollDistance * dir);
    }

    // Update slider position
    this.sliderTarget.style.transform = `translateX(${this.scrollPos * -1}px)`;

    // Hide off-screen slides from screen readers
    this.slideTargets.forEach((slide, index) => {
      slide.style.visibility = 'visible';

      if (this.index !== index) {
        slide.style.visibility = 'hidden';
      }
    });

    this.dotTargets.forEach((dot, index) => {
      // Reset/set active dot
      dot.setAttribute('aria-current', index === this.index ? 'location' : 'false');

      // Also set content targets if it exists (alternate "dots")
      if (this.contentTargets[index]) {
        this.contentTargets[index].setAttribute('aria-current', index === this.index ? 'location' : 'false');
      }
    });

    this.enableScrollButtons();
    this.disableScrollButtons();
  }
}
